<template>
    <div>
        <div class="cajaSuperior">
            <b-row>
                <b-col cols="12" md="3">
                    <h2>programar<br/>visita</h2>
                </b-col>

                <!-- Formulario consulta -->
                <b-col>
                    <div class="contenedorForm pl-5">
                        <FormularioAgendarVisita @envioFormulario="enviarForm"/>
                    </div>
                </b-col>
            </b-row>
        </div>

        <br /> <br />
        <b-row v-if="visitasCargadas.length>0">
            <b-col>
                <b-table
                     id="visitasLicencia" 
                    striped 
                    responsive 
                    small 
                    bordered 
                    :items="visitasCargadas"
                    :fields="camposTabla" 
                    :per-page="perPage"
                    :current-page="currentPage"
                    :tbody-tr-class="rowClass" >
                    <template #cell(eliminar)="row">
                        <b-button size="sm" @click="mandarABorrar(row.item.id)" class="mr-1 text-center" variant="link" block>
                            <b-icon icon="x-circle" variant="danger"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <b-row v-if="visitasCargadas.length>0">
            <b-col>
                <b-pagination 
                    v-model="currentPage" 
                    :total-rows="rows" 
                    :per-page="perPage" 
                    aria-controls="visitasLicencia"></b-pagination>
            </b-col>
             <b-col class="text-right">
                <b-button :to="{name: 'consultaLicencias'}">
                   Volver
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import{mapActions, mapState} from 'vuex';
    import FormularioAgendarVisita from '../components/agendarVisita/FormularioAgendarVisita.vue'
     import moment from 'moment'
    import { traerNombreEstado } from '../constantes';
    import {encontrarError} from '../contantesErrores';

    export default {
        name: 'AgendarVisita',
        components: {
            FormularioAgendarVisita
        },
        data(){
            return{
                camposTabla: [
                    'num_acta',
                    {
                        key: 'estado',
                        formatter: (value)=>{
                            let estado= traerNombreEstado(value)
                            return estado.nombre
                        }
                    },
                    {
                        key: 'fecha_plan',
                        label: 'Fecha Planeada',
                        formatter: (value) => {
                            return moment(value).format("DD-MM-YYYY");
                        },
                    },
                    'hora_visita',
                    'eliminar'
                ],
                perPage: 10,
                currentPage: 1,
            }
        },
        mounted() {
            this.cargarVisitasLicencia()
        },
        computed:{
            ...mapState('consultaLicencias', ['licenciaSeleccionada']),
            ...mapState('agendarVisita', ['visitasCargadas', 'resEliminar']),
            rows() {
                return this.visitasCargadas.length
            }
        },
        methods:{
            ...mapActions('agendarVisita', ['buscarVisitasLicenciaSeleccionada', 'guardarVisita', 'eliminarVisitaId']),

            rowClass(item, type) {
                if (!item || type !== 'row') return
                return 'rowspace'
            },

            async cargarVisitasLicencia(){
                try{
                    await this.buscarVisitasLicenciaSeleccionada(this.licenciaSeleccionada.id)
                }catch(e){
                    encontrarError(this, e.data)
                }
            },

             async enviarForm(datos){
                 try{
                     await this.guardarVisita(datos)
                     this.cargarVisitasLicencia()
                 }catch(e){
                     encontrarError(this, e.data)
                 }
            },

            mandarABorrar(id){
                this.$bvModal.msgBoxConfirm('Se va a eliminar la visita seleccionada, ¿Desea continuar?.', {
                    title: 'Por favor confirme',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Continuar',
                    cancelTitle: 'Cancelar',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                    })
                    .then(value => {
                        if(value){
                        this.eliminar(id)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            async eliminar(id){
                try{
                    await this.eliminarVisitaId(id)
                    this.cargarVisitasLicencia()
                }catch(e){
                    encontrarError(this, e.data)
                }
            },
        }
    }
</script>