<template>
    <b-form @submit.prevent="enviarForm" validated>
        <b-form-row>
            <b-col cols="12">
                <label  for="licencia">Licencia No.</label>
                <b-form-input
                    required='required'
                    id="licencia"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Licencia No."
                    v-model="form.licencia"
                    readonly
                ></b-form-input>
            </b-col>

            <b-col cols="12" lg="4" align-self="end">
                <label  for="fechaInicio">Fecha:</label>
                <b-form-datepicker 
                    id="fecha" 
                    v-model="form.fecha_planeada" 
                    placeholder="Elige una fecha"  
                    locale="es"
                    :min="form.fechaMinima"
                    labelNoDateSelected="No hay fecha"
                    labelHelp="Use las flechas del teclado para navegar entre fechas"></b-form-datepicker>
            </b-col>

            <b-col cols="12" lg="4" align-self="end">
                <label  for="hora">Hora:</label>
                <b-form-timepicker 
                    required
                    id='hora' v-model="form.hora_planeada" 
                    placeholder="A.M." 
                    labelCloseButton = "Cerrar"
                    labelNoTimeSelected = "No hay hora"
                    locale="es" ></b-form-timepicker>
            </b-col>

            <b-col cols="12" lg="4"  align-self="end">
                <b-button type="submit" block class="vertical-align mt-2" variant="secondary" :disabled="validarBottonGuardar">
                    <b-icon icon="plus-circle"></b-icon>
                    Agregar Visita
                </b-button>
            </b-col>
        </b-form-row>
    </b-form>
</template>

<script>
    import {mapState} from 'vuex';
    import moment from 'moment'
    export default {
        name:'FormularioAgendarVisita',
        data(){
            return{
                usuario:{},
                form: {
                    id_licencia: null,
                    licencia: null,
                    fecha_planeada: null,
                    hora_planeada:null,
                    direccion: null,
                    fechaMinima: moment().format('YYYY-MM-DD')
                },
            }
        },
        mounted(){
            this.form.id_licencia = this.licenciaSeleccionada.id
            this.form.licencia = this.licenciaSeleccionada.num_acto
            this.form.direccion = this.licenciaSeleccionada.direccion

            if(localStorage.getItem('usuario')){
                try {
                    this.usuario = JSON.parse(localStorage.getItem('usuario'));
                } catch(e) {
                    localStorage.removeItem('usuario');
                }
            }
        },
        computed:{
            ...mapState('consultaLicencias', ['licenciaSeleccionada']),
            ...mapState('agendarVisita', ['visitasCargadas']),
            validarBottonGuardar(){
                let validate = false
                if(!this.form.fecha_planeada || !this.form.hora_planeada) {
                    validate = true
                }
                return validate
            },
        },
        methods:{
            enviarForm(){
                let datosAGuardar = {
                    visita: { 
                        id_licencia :  this.form.id_licencia,
                        id_usuario :  this.usuario.id_usuario,
                        fecha_registro :  new Date(),
                        fecha_plan :  this.form.fecha_planeada,
                        hora_visita :  this.form.hora_planeada,
                        direccion: this.form.direccion,
                        estado: 0
                    }
                }
                let coinciden = this.validarFechayHora(datosAGuardar.visita)
                if(!coinciden){
                    this.$emit('envioFormulario', datosAGuardar)             
                }else{
                    this.mostrarError()
                }
            },

            resetForm(){
                this.form={
                     id_licencia: null,
                    licencia: null,
                    fecha_planeada: null,
                    hora_planeada:null
                }
            },

            validarFechayHora(datos){
                let coincide;
                for(let i=0; i<this.visitasCargadas.length > 0; i++){
                    let item = this.visitasCargadas[i]
                    if(moment(item.fecha_visita).format('YYYY-MM-DD') == moment(datos.fecha_registro).format('YYYY-MM-DD')){
                        if(item.hora_visita == datos.hora_visita){
                            coincide = true
                            break
                        }else{
                            coincide = false
                        }
                    }else{
                        coincide = false
                    }
                }
                return coincide
            },

            mostrarError() {
                this.$bvModal.msgBoxOk('Ya coincide una visita con esta fecha y hora.', {
                    title: 'Error',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'danger',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                    })
                    .then(() => {
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        }
    }
</script>